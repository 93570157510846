import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { AnalyticsService } from "../../services/analytics";
import RefreshIcon from "@mui/icons-material/Refresh";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { IStats, IUserData } from "./interfaces";

const columns: GridColDef[] = [
  { field: "firstName", headerName: "First Name", width: 100 },
  { field: "lastName", headerName: "Last Name", width: 100 },
  { field: "email", headerName: "Email", width: 200 },
  { field: "country", headerName: "Country", width: 120 },
  {
    field: "investment",
    headerName: "Amount to Invest",
    width: 140,
    sortComparator: (a, b) => parseInt(a) - parseInt(b),
    valueFormatter: (params) =>
      parseInt(params.value).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
  },
  {
    field: "accredited",
    headerName: "Accredited",
    valueFormatter: (params) => {
      if (params.value === "true") {
        return "Yes";
      } else if (params.value === "false") {
        return "No";
      } else {
        return "Unknown";
      }
    },
  },
  { field: "referrals", headerName: "Referrals" },
  {
    field: "createdAt",
    headerName: "Created",
    width: 200,
    sortComparator: (a, b) => new Date(a).getTime() - new Date(b).getTime(),
    valueFormatter: (params) => new Date(params.value),
  },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28"];
const styles = {
  statsBox: {
    width: "50%",
    display: "inline-block",
    pt: 2,
    pb: 2,
  },
};

export const WaitlistAnalytics = () => {
  const [data, setData] = useState<IUserData[]>([]);
  const [stats, setStats] = useState<IStats>({});
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [toDelete, setToDelete] = useState<number[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getWaitlistData();
  }, []);

  useEffect(() => {
    let totalSignups = data.length,
      dailySignups = data.filter((user) => {
        const difference = Math.abs(
          new Date(user.createdAt).getTime() - new Date().getTime()
        );

        return difference / (60 * 60 * 1000) < 24;
      }).length,
      totalPledged = 0,
      targetPledged = 0;

    data.forEach((user) => {
      if (user.accredited === "true" && user.country === "United States") {
        targetPledged += parseInt(user.investment);
      }
      totalPledged += parseInt(user.investment);
    });

    setStats({ totalSignups, dailySignups, totalPledged, targetPledged });
  }, [data]);

  const getPieChart = (fieldName: string) => {
    const map = new Map();
    data.forEach((user: any) => {
      const fieldVal = user[fieldName];
      if (map.has(fieldVal)) {
        map.set(fieldVal, map.get(fieldVal) + 1);
      } else {
        map.set(fieldVal, 1);
      }
    });

    let pieData = [] as any[];
    map.forEach((value, key) => {
      if (key === "null") key = "Unknown";
      if (key === "false") key = "Non-accredited";
      if (key === "true") key = "Accredited";

      pieData.push({ name: key, value });
    });

    return (
      <ResponsiveContainer width="100%" height={228}>
        <PieChart>
          <Pie
            dataKey="value"
            data={pieData}
            cx="50%"
            cy="50%"
            innerRadius={45}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={5}
            label
          >
            {pieData.map((entry: any, index: number) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    );
  };

  const getWaitlistData = () => {
    setLoading(true);
    AnalyticsService.getWaitlistData()
      .then((res) => {
        setData(res.data);
      })
      .finally(() => setLoading(false));
  };

  const downloadWaitlistData = () => {
    const tempLink = document.createElement("a");
    AnalyticsService.downloadWaitlistData()
      .then((res) => {
        const blobUrl = window.URL.createObjectURL(new Blob([res.data]));
        tempLink.style.display = "none";
        tempLink.href = blobUrl;
        tempLink.setAttribute("download", "waitlist.csv");
        document.body.appendChild(tempLink);
        tempLink.click();
      })
      .finally(() => {
        document.body.removeChild(tempLink);
      });
  };

  const performBatchDelete = () => {
    AnalyticsService.deleteRecord(toDelete)
      .then((res) => {
        getWaitlistData();
      })
      .finally(() => {
        setOpen(false);
      });
  };

  return (
    <>
      <Grid item sm={4} xs={12}>
        <Paper sx={{ p: 2, height: 300 }} style={{ verticalAlign: "middle" }}>
          <div
            style={{ width: "100%", position: "relative", marginBottom: "8px" }}
          >
            <Typography
              variant="h6"
              color="#0F6EFF"
              sx={{ display: "inline-block" }}
            >
              User Breakdown (Accredited)
            </Typography>
            <IconButton
              onClick={getWaitlistData}
              sx={{ float: "right", padding: "2px" }}
            >
              <RefreshIcon />
            </IconButton>
          </div>
          {getPieChart("accredited")}
        </Paper>
      </Grid>
      <Grid item sm={4} xs={12}>
        <Paper sx={{ p: 2, height: 300 }}>
          <div
            style={{ width: "100%", position: "relative", marginBottom: "8px" }}
          >
            <Typography
              variant="h6"
              color="#0F6EFF"
              sx={{ display: "inline-block" }}
            >
              User Breakdown (Country)
            </Typography>
            <IconButton
              onClick={getWaitlistData}
              sx={{ float: "right", padding: "2px" }}
            >
              <RefreshIcon />
            </IconButton>
          </div>
          {getPieChart("country")}
        </Paper>
      </Grid>
      <Grid item sm={4} xs={12}>
        <Paper sx={{ p: 2, height: 300 }}>
          <div
            style={{ width: "100%", position: "relative", marginBottom: "8px" }}
          >
            <Typography
              variant="h6"
              color="#0F6EFF"
              sx={{ display: "inline-block" }}
            >
              Interested Capital
            </Typography>
            <IconButton
              onClick={getWaitlistData}
              sx={{ float: "right", padding: "2px" }}
            >
              <RefreshIcon />
            </IconButton>
          </div>
          <Box sx={styles.statsBox}>
            <Typography variant="h4">
              {stats.totalSignups || "loading"}
            </Typography>
            <Typography variant="body1" color="#0F6EFF">
              Total Signups
            </Typography>
          </Box>
          <Box sx={styles.statsBox}>
            <Typography variant="h4">
              {stats.dailySignups?.toString() || "loading"}
            </Typography>
            <Typography variant="body1" color="#0F6EFF">
              Signups in 24h
            </Typography>
          </Box>
          <Box sx={styles.statsBox}>
            <Typography variant="h5">
              {stats.totalPledged?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }) || "loading"}
            </Typography>
            <Typography variant="body1" color="#0F6EFF">
              Pledged in Total
            </Typography>
          </Box>
          <Box sx={styles.statsBox}>
            <Typography variant="h5">
              {stats.targetPledged?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }) || "loading"}
            </Typography>
            <Typography variant="body1" color="#0F6EFF">
              By U.S. Accredited
            </Typography>
          </Box>
        </Paper>
      </Grid>
      <Grid item sm={12} xs={12}>
        <Paper sx={{ p: 2 }}>
          <div
            style={{ width: "100%", position: "relative", marginBottom: "8px" }}
          >
            <Typography
              variant="h6"
              color="#0F6EFF"
              sx={{ display: "inline-block" }}
            >
              Early Access Data
            </Typography>

            <IconButton
              onClick={getWaitlistData}
              sx={{ float: "right", padding: "2px" }}
            >
              <RefreshIcon />
            </IconButton>
            <IconButton
              onClick={downloadWaitlistData}
              sx={{ float: "right", padding: "2px" }}
            >
              <DownloadIcon />
            </IconButton>
            <IconButton
              onClick={() => setOpen(true)}
              sx={{ float: "right", padding: "2px" }}
              disabled={toDelete.length === 0}
            >
              <DeleteIcon />
            </IconButton>
          </div>
          <DataGrid
            rows={data}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            rowsPerPageOptions={[25, 50, 100]}
            loading={loading}
            density="compact"
            disableSelectionOnClick={true}
            onSelectionModelChange={(ids) => {
              setToDelete(ids as number[]);
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "createdAt", sort: "desc" }],
              },
            }}
            checkboxSelection
            autoHeight
          />
          <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Perform Batch Delete</DialogTitle>
            <DialogContent>
              <DialogContentText>
                You have selected {toDelete.length} records to delete. Are you
                sure you want to continue?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button onClick={performBatchDelete}>Confirm</Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Grid>
    </>
  );
};
