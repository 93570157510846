import axios from "axios";
import { API_URL } from "./config";

export const AnalyticsService = {
  getWaitlistData: async () => {
    var config = {
      method: "get",
      url: `${API_URL}/admin/waitlist`,
      headers: {
        Authorization: sessionStorage.getItem("jwt") as string,
      },
    };

    const response = await axios(config);
    return response;
  },
  downloadWaitlistData: async () => {
    var config = {
      method: "get",
      url: `${API_URL}/admin/waitlist/download`,
      responseType: "blob" as any,
      headers: {
        Authorization: sessionStorage.getItem("jwt") as string,
        "Content-Disposition": "attachment",
      },
    };

    const response = await axios(config);
    return response;
  },
  deleteRecord: async (ids: number[]) => {
    const queryParams = ids.join(",");
    var config = {
      method: "delete",
      url: `${API_URL}/admin/waitlist?id=` + queryParams,
      headers: {
        Authorization: sessionStorage.getItem("jwt") as string,
      },
    };

    const response = await axios(config);
    return response;
  },
};
