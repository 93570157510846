import { createContext, ReactNode, useContext, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { AuthService } from "../services/auth";

interface IAuthContext {
  user?: any;
  error?: string;
  loading: boolean;
  login: (username: string, password: string) => void;
  logout: () => void;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<any>();
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const login = (username: string, password: string) => {
    setLoading(true);
    AuthService.login({ username, password })
      .then((response) => {
        setUser(response.data.jwt);
        sessionStorage.setItem("jwt", response.data.jwt);
        navigate("/");
      })
      .catch((error) => {
        setError("Incorrect credentials");
      })
      .finally(() => setLoading(false));
  };

  const logout = () => {
    setUser(undefined);
    setError(undefined);
    sessionStorage.removeItem("jwt");
  };

  return (
    <AuthContext.Provider value={{ user, error, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const ProtectedPage = ({ children }: { children: JSX.Element }) => {
  const { user } = useAuth();
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ fromt: location }} />;
  }

  return children;
};
