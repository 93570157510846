import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { FormEvent } from "react";
import { useAuth } from "../context/auth";
import logo from "../static/logo.svg";

export const LoginPage = () => {
  const { login, loading, error } = useAuth();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    login(
      formData.get("username") as string,
      formData.get("password") as string
    );
  };

  return (
    <Grid
      container
      component="main"
      style={{ minHeight: "100vh" }}
      alignItems="center"
      justifyContent="center"
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <Grid item component={Paper} elevation={6} square padding="16px">
          <Box display="flex" flexDirection="column" alignItems="center">
            <img src={logo} alt="Homebase Logo" width={96} />
            <Typography variant="h5" component="h1">
              Sign In
            </Typography>
            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Password"
                name="password"
                autoComplete="current-password"
                type="password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
              >
                Sign In
              </Button>
            </Box>
            {error && <Alert severity="error">{error}</Alert>}
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
