import axios from "axios";
import { API_URL } from "./config";

export const AuthService = {
  login: async (params: { username: string; password: string }) => {
    var data = JSON.stringify(params);

    console.log(API_URL);

    var config = {
      method: "post",
      url: `${API_URL}/admin/login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    const response = await axios(config);

    return response;
  },
};
